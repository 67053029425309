export function buildGrafanaCigarraPanelUrl(panelId: number) {
  const baseUrl = 'https://skales.7science.com.br/grafana';
  const now = new Date();
  const to = now.getTime();
  const from = to - 24 * 60 * 60 * 1000;
  const orgId = 1;

  const url = `${baseUrl}/d-solo/ce3tw68vmwnb4c/cigarra?from=${from}&to=${to}&timezone=browser&theme=light&__feature.dashboardSceneSolo&orgId=${orgId}&panelId=${panelId}`;
  return url;
}

export function buildGrafanaSmartGridPanelUrl(panelId: number) {
  const baseUrl = 'https://skales.7science.com.br/grafana';
  const now = new Date();
  const to = now.getTime();
  const from = to - 24 * 60 * 60 * 1000;
  const orgId = 1;

  const url = `${baseUrl}/d-solo/ae7teat54bgg0f/smart-grid-sensor?from=${from}&to=${to}&timezone=browser&theme=light&__feature.dashboardSceneSolo&orgId=${orgId}&panelId=${panelId}`;
  return url;
}

export function buildGrafanaQeeTrafoPanelUrl(panelId: number, deviceId: number | null, clientID: number | null) {
  const baseUrl = 'https://skales.7science.com.br/grafana';
  const now = new Date();
  const to = now.getTime();
  const from = to - 24 * 60 * 60 * 1000;
  const orgId = 1;

  const url = `${baseUrl}/d-solo/ee3tw7pcjmghsd/7trafo?from=${from}&to=${to}&timezone=browser&theme=light&__feature.dashboardSceneSolo&orgId=${orgId}&var-client=${clientID}&var-device=${deviceId}&var-panel=${panelId}&panelId=${panelId}`;
  return url;
}
