<template>
  <PageTitle pageTitle="Monitoramento de Dispositivos" mainTitle="Monitoramento de Dispositivos" />
  <v-row>
    <v-col cols="12" lg="12" md="12">
      <v-card class="mb-30 pingo-card">
        <div>
          <div class="v-card-header border-bottom">
            <h5 class="mb-0">Selecione o dispositivo</h5>
          </div>
          <form>
            <v-autocomplete
              v-model="deviceType"
              :items="['Cigarra', 'Cigarra Plus', 'Smart Grid Sensor', '7Trafo']"
              class="example-full-width"
              label="Dispositivo"
              variant="outlined"
              density="comfortable"
              rounded="lg"
              :focused="false"
            ></v-autocomplete>
          </form>
        </div>

        <div v-if="deviceType" class="my-6">
          <v-expansion-panels>
            <v-expansion-panel>
              <template v-slot:title>
                <div class="d-flex align-center">
                  <span>Filtros</span>
                  <v-badge v-if="totalAppliedFilters > 0" :content="totalAppliedFilters" color="white" inline />
                </div>
              </template>
              <v-expansion-panel-text>
                <VForm class="w-100">
                  <v-row
                    class="w-100 d-flex align-center justify-center"
                    cols="12"
                    lg="6"
                    md="6"
                    fluid
                    v-if="devicesList.length === 0 && clients.length === 0"
                  >
                    <v-progress-circular indeterminate color="grey" class="my-6"></v-progress-circular>
                  </v-row>
                  <v-row class="w-100" v-else>
                    <v-col cols="12" lg="12" md="12" fluid class="d-flex align-start justify-center flex-wrap p-0">
                      <v-col cols="12" lg="6" md="6" fluid>
                        <div class="my-6">
                          <v-date-input
                            v-model="filters.date"
                            placeholder="Data"
                            id="date"
                            density="comfortable"
                            label="Data"
                            multiple="range"
                            clearable
                            prepend-icon=""
                            variant="outlined"
                            bg-color="white"
                            color="#35ac67"
                            rounded="lg"
                            theme="primary"
                            show-adjacent-months
                            keyboardDateTime
                            persistent-counter
                            ok-text="Aplicar"
                            cancel-text="Cancelar"
                            :error-messages="dateErrorMessage"
                            @click:clear="handleClearDate"
                          />
                          <p class="mb-0 ms-2 text-danger text-xs" v-if="dateErrorMessage">
                            {{ dateErrorMessage }}
                          </p>
                        </div>
                        <div class="my-6">
                          <MultiSelect
                            v-model="filters.devicesId"
                            :itemsList="devicesList"
                            :itemValue="'device_id'"
                            :itemTitle="'device_uuid'"
                            :id="'devices'"
                            :label="'Dispositivos'"
                            :placeholder="'Dispositivos'"
                            :noDataText="'Nenhum resultado encontrado!'"
                            :currentPage="currentDevicePage"
                            :totalPages="totalDevicePages"
                            @update:search="onDeviceSearch"
                            @load-more-items="loadMoreDevices"
                          />
                        </div>
                        <div class="my-6">
                          <v-text-field
                            v-model="filters.lineNumber"
                            density="comfortable"
                            label="Número da Linha"
                            id="lineNumber"
                            placeholder="Número da Linha"
                            clearable
                            variant="outlined"
                            bg-color="white"
                            color="#35ac67"
                            rounded="lg"
                            theme="primary"
                          ></v-text-field>
                        </div>
                        <div class="my-6">
                          <v-autocomplete
                            :items="STATUS"
                            v-model="filters.status"
                            :item-props="true"
                            item-value="value"
                            item-title="name"
                            label="Status"
                            id="status"
                            density="comfortable"
                            placeholder="Status"
                            chips
                            closable-chips
                            clearable
                            variant="outlined"
                            bg-color="white"
                            color="#35ac67"
                            rounded="lg"
                            theme="primary"
                          ></v-autocomplete>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6" md="6" fluid>
                        <div class="my-6">
                          <MultiSelect
                            v-model="filters.clients"
                            :itemsList="clients"
                            :itemValue="'client_id'"
                            :itemTitle="'client_name'"
                            :id="'client'"
                            :label="'Clientes'"
                            :placeholder="'Selecione os clientes'"
                            :noDataText="'Nenhum cliente encontrado'"
                            :currentPage="currentClientPage"
                            :totalPages="totalClientPages"
                            @update:search="onClientSearch"
                            @load-more-items="loadMoreClients"
                          />
                        </div>
                        <div class="my-6">
                          <MultiSelect
                            v-model="filters.operationalNumber"
                            :itemsList="operationalNumberList"
                            :itemValue="'operational_number'"
                            :itemTitle="'operational_number'"
                            :id="'operationalNumber'"
                            :label="'Número Operacional'"
                            :placeholder="'Selecione os números operacionais'"
                            :noDataText="'Nenhum número operacional encontrado'"
                            :currentPage="currentOperationalNumberPage"
                            :totalPages="totalOperationalNumber"
                            @update:search="onOperationalNumberSearch"
                            @load-more-items="loadMoreOperationalNumber"
                          />
                        </div>
                        <div class="my-6">
                          <v-autocomplete
                            v-model="filters.technology"
                            :items="EQUIPMENT_TECNOLOGIES"
                            id="technology"
                            density="comfortable"
                            label="Tecnologia"
                            placeholder="Tecnologia"
                            multiple
                            chips
                            closable-chips
                            clearable
                            variant="outlined"
                            bg-color="white"
                            color="#35ac67"
                            rounded="lg"
                            theme="primary"
                          ></v-autocomplete>
                        </div>
                      </v-col>
                    </v-col>
                    <div class="w-100 pb-3">
                      <div class="text-end d-flex align-center justify-end">
                        <v-btn
                          type="button"
                          class="elevation-0 ps-20 pe-20 mr-2 button elevation-0"
                          color="grey"
                          @click="clearFilters"
                          variant="text"
                        >
                          Limpar filtros
                        </v-btn>
                        <v-btn
                          type="button"
                          class="default-btn ps-30 pe-30 button elevation-0"
                          color="#35ac67"
                          rounded="lg"
                          :loading="loadingButtonApply"
                          :disabled="isFilterButtonDisabled || loadingButtonApply"
                          @click="applyFilters"
                        >
                          Filtrar
                        </v-btn>
                      </div>
                    </div>
                  </v-row>
                </VForm>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>

        <div class="pingo-table-content">
          <div class="pingo-table v-elevation-z8">
            <div class="pingo-table-content">
              <div v-if="deviceType">
                <v-data-table-server
                  :headers="DEVICE_MONITORING_HEADERS[deviceType]"
                  :items="data"
                  :items-length="totalItems"
                  :loading="loading"
                  :page="currentPage"
                  :items-per-page="itemsPerPage"
                  :items-per-page-options="[10, 20, 30, 40, 50]"
                  @update:options="onOptionsChange"
                  items-per-page-text="Linhas por página"
                  loading-text="Carregando..."
                  no-data-text="Nenhum resultado encontrado!"
                  no-results-text="Nenhum resultado encontrado!"
                  show-current-page
                >
                  <template v-slot:top>
                    <div class="d-flex align-items-center justify-content-space-between mt-10 mb-4">
                      <div class="d-flex align-items-center form-search-table">
                        <form class="pingo-search-box" @submit.prevent>
                          <input
                            type="text"
                            class="input-search border-radius"
                            placeholder="Pesquise aqui..."
                            v-model="search"
                            @input="onSearchInput"
                            density="comfortable"
                            rounded="lg"
                          />
                          <button type="submit"><i class="ri-search-line"></i></button>
                        </form>
                      </div>
                    </div>
                  </template>
                </v-data-table-server>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import {
  formatDate,
  formatDateRange,
  formatEpochDate,
  replaceSpacesWithHyphens,
  formatDateToYMDHM,
} from '../../utils/format';
import { DEVICE_MONITORING_HEADERS, EQUIPMENT_TECNOLOGIES, STATUS } from '../../utils/constants';
import { getDeviceMonitoring } from '../../services/monitoring';
import { computed, ref, watch } from 'vue';
import { useClients } from '@/utils/composable/useClients';
import { useOperationalNumber } from '@/utils/composable/useOperationalNumber';
import { useDevices } from '@/utils/composable/UseDevices';
import { VDateInput } from 'vuetify/lib/labs/components.mjs';
import PageTitle from '@/components/common/PageTitle.vue';
import MultiSelect from '@/components/common/MultiSelect.vue';

export default {
  name: 'MonitoringDeviceView',
  components: { VDateInput, PageTitle, MultiSelect },
  data() {
    return {
      DEVICE_MONITORING_HEADERS,
      EQUIPMENT_TECNOLOGIES,
      STATUS,
      model: null,
    };
  },
  setup() {
    const data = ref<any[]>([]);
    const deviceType = ref<string>('');
    const loading = ref<boolean>(false);
    const totalItems = ref<number>(0);
    const itemsPerPage = ref<number>(10);
    const totalPages = ref<number>(0);
    const currentPage = ref<number>(1);
    const search = ref<string>('');
    const orderBy = ref<string>('');
    const direction = ref<string>('');

    const loadingButtonApply = ref<boolean>(false);

    const { clients, loadMoreClients, isClientsLoading, totalClientPages, currentClientPage, fetchClients } =
      useClients();
    const { devicesList, loadMoreDevices, isDevicesLoading, currentDevicePage, totalDevicePages, fetchDeviceList } =
      useDevices();

    const {
      operationalNumberList,
      loadMoreOperationalNumber,
      isOperationalNumberLoading,
      currentOperationalNumberPage,
      totalOperationalNumber,
      fetchOperationalNumberList,
      onOperationalNumberSearch,
    } = useOperationalNumber();

    const dateErrorMessage = ref('');

    const today = new Date();
    const startOfDay = new Date(today.setHours(0, 0, 0, 0));
    const endOfDay = new Date(today.setHours(23, 59, 59, 999));

    const formattedStartOfDay = formatDateToYMDHM(startOfDay);
    const formattedEndOfDay = formatDateToYMDHM(endOfDay);

    const filters = ref({
      date: [today],
      startDate: formattedStartOfDay,
      endDate: formattedEndOfDay,
      devicesId: [],
      clients: [] as string[],
      operationalNumber: [],
      lineNumber: '',
      status: null,
      technology: [],
    });

    watch(
      () => filters.value.date,
      (dates: any) => {
        if (Array.isArray(dates) && dates.length > 0) {
          const startDate = new Date(dates[0]).getTime();
          const endDate = new Date(dates[dates.length - 1]).getTime();
          const diffInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);

          if (diffInDays >= 30) {
            dateErrorMessage.value = 'O intervalo permitido é de no máximo 30 dias';
          } else {
            dateErrorMessage.value = '';
          }
        }
      }
    );

    watch(deviceType, () => {
      if (deviceType.value) {
        loadDeviceMonitoringData();
        clearFilters();
      }
    });

    const applyFilters = () => {
      const formattedDates = formatDateRange(filters.value.date);

      if (!formattedDates) {
        filters.value.startDate = '';
        filters.value.endDate = '';
        return;
      }

      filters.value.startDate = formattedDates.startDate;
      filters.value.endDate = formattedDates.endDate;

      if (filters.value.startDate === filters.value.endDate) {
        const endDate = new Date(filters.value.endDate);
        endDate.setHours(23, 59, 59, 999);
        filters.value.endDate = formatDateToYMDHM(endDate);
      }

      loadingButtonApply.value = true;
      loadDeviceMonitoringData().finally(() => {
        loadingButtonApply.value = false;
      });
    };

    const loadDeviceMonitoringData = async () => {
      if (!deviceType.value) return;

      loading.value = true;
      data.value = [];

      const hyphenatedType = replaceSpacesWithHyphens(deviceType.value);
      const status = filters.value.status ? [filters.value.status] : [];

      const params = {
        deviceType: hyphenatedType,
        itemsPerPage: itemsPerPage.value,
        page: currentPage.value,
        orderBy: orderBy.value,
        direction: direction.value,
        search: search.value,
        startDate: filters.value.startDate,
        endDate: filters.value.endDate,
        devicesId: filters.value.devicesId,
        clients: filters.value.clients,
        operationalNumber: filters.value.operationalNumber,
        lineNumber: filters.value.lineNumber,
        status: status,
        technology: filters.value.technology,
      };

      try {
        const response = await getDeviceMonitoring(params);

        data.value = response.data.map((item) => ({
          ...item,
          date_event: formatDate(item.date_event),
          latest_date: formatDate(item?.latest_date),
          last_msg_epoch: formatEpochDate(item?.last_msg_epoch),
          temperature: `${item.temperature} °C`,
          status: item.status === true ? 'Ativo' : 'Inativo',
          power: `${item.power} %`,
        }));

        totalItems.value = response.recordsTotal;
        itemsPerPage.value = response.itemsPerPage;
        currentPage.value = response.page;
        totalPages.value = response.totalPages;
      } catch (error) {
        console.error('Erro ao carregar os dados dos relatórios:', error);
      } finally {
        loading.value = false;
      }
    };

    const onOptionsChange = (event: {
      sortBy: { key: string; order: string }[];
      itemsPerPage: number;
      page: number;
      search: string;
    }) => {
      if (event.sortBy.length > 0) {
        orderBy.value = event.sortBy[0].key;
        direction.value = event.sortBy[0].order;
      } else {
        orderBy.value = '';
        direction.value = '';
      }

      itemsPerPage.value = event.itemsPerPage;
      currentPage.value = event.page;
      search.value = event.search;
      loadDeviceMonitoringData();
    };

    const handleClearDate = () => {
      filters.value.date = [today];
      filters.value.startDate = formattedStartOfDay;
      filters.value.endDate = formattedEndOfDay;
      dateErrorMessage.value = '';
    };

    const isFilterActive = (filter: any) => {
      if (Array.isArray(filter)) {
        return filter.length > 0;
      }
      return !!filter;
    };

    const hasActiveFilters = () => {
      return Object.values(filters.value).some(isFilterActive);
    };

    const isDateErrorPresent = () => {
      return dateErrorMessage.value !== '';
    };

    const isFilterButtonDisabled = computed(() => {
      return !hasActiveFilters() || isDateErrorPresent();
    });

    const onClientSearch = async (searchTerm: string) => {
      if (searchTerm) {
        const termExists = clients.value.some((client) => client.client_name.includes(searchTerm));

        if (!termExists) {
          const searchedClients = await fetchClients(1, searchTerm);

          const uniqueClients = searchedClients.filter((searchedClient) => {
            return !clients.value.some((client) => client.client_id === searchedClient.client_id);
          });

          clients.value = [...uniqueClients, ...clients.value];
        }
      }
    };

    const onSearchInput = () => {
      loadDeviceMonitoringData();
    };

    const onDeviceSearch = async (searchTerm: string) => {
      if (searchTerm) {
        const termExists = devicesList.value.some((device) => device.device_uuid.includes(searchTerm));

        if (!termExists) {
          const searchedDevices = await fetchDeviceList(1, searchTerm);

          const uniqueDevices = searchedDevices.filter((searchedDevice) => {
            return !devicesList.value.some((device) => device.device_id === searchedDevice.device_id);
          });

          devicesList.value = [...uniqueDevices, ...devicesList.value];
        }
      }
    };

    const clearFilters = () => {
      filters.value = {
        date: [today],
        startDate: formattedStartOfDay,
        endDate: formattedEndOfDay,
        devicesId: [],
        clients: [],
        operationalNumber: [],
        lineNumber: '',
        status: null,
        technology: [],
      };
      dateErrorMessage.value = '';

      loadDeviceMonitoringData();
    };

    const isFilterApplied = (filter: any) => {
      if (Array.isArray(filter)) {
        return filter.length > 0;
      }
      return !!filter;
    };

    const totalAppliedFilters = computed(() => {
      const { startDate, endDate, ...otherFilters } = filters.value;
      return Object.values(otherFilters).filter(isFilterApplied).length;
    });

    const onRefreshTable = () => {
      loadDeviceMonitoringData();
    };

    return {
      data,
      deviceType,
      loading,
      loadDeviceMonitoringData,
      totalItems,
      itemsPerPage,
      currentPage,
      onOptionsChange,
      totalPages,
      filters,
      applyFilters,
      clients,
      loadMoreClients,
      isClientsLoading,
      totalClientPages,
      currentClientPage,
      devicesList,
      loadMoreDevices,
      isDevicesLoading,
      currentDevicePage,
      totalDevicePages,
      dateErrorMessage,
      handleClearDate,
      isFilterButtonDisabled,
      loadingButtonApply,
      onClientSearch,
      onDeviceSearch,
      clearFilters,
      totalAppliedFilters,
      search,
      onSearchInput,
      onRefreshTable,
      operationalNumberList,
      loadMoreOperationalNumber,
      isOperationalNumberLoading,
      currentOperationalNumberPage,
      totalOperationalNumber,
      fetchOperationalNumberList,
      onOperationalNumberSearch,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-progress-linear__background,
  .v-progress-linear__buffer {
    background: rgb(53 172 103);
  }

  .v-progress-linear__determinate,
  .v-progress-linear__indeterminate {
    background: rgb(53 172 103);
  }

  .footer-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;

    .items-per-page {
      border: 1px solid #ededee;
      border-radius: 10px;
      padding: 6px 4px;
      width: 76px;
      margin: 10px;
    }
  }
  .form-search-table {
    width: 100% !important;
    margin: 17px 0px 17px 0px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;

    form {
      width: 100%;
      border-radius: 6px;
    }

    .ri-search-line {
      color: #dbdbdb;
    }
  }

  .v-expansion-panel--active > .v-expansion-panel-title:not(.v-expansion-panel-title--static) {
    background-color: #848587;
    color: white;
    border-radius: 10px 10px 0 0;
  }

  .v-expansion-panel--active > .v-expansion-panel-title:not(.v-expansion-panel-title--static) {
    min-height: 3.25rem;
  }

  .v-expansion-panel__shadow {
    box-shadow: none;
  }

  .v-expansion-panel-title {
    background-color: #979797;
    border-radius: 10px;
    color: white;
  }

  .v-expansion-panel-text__wrapper {
    border: 1px solid #979797;
    border-radius: 0 0 10px 0.625rem;
  }

  .mdi-close-circle {
    font-size: 18px;
  }

  .v-chip.v-chip--size-small {
    font-size: 11px;
    padding: 0 10px;
    height: 22px;
    margin: 10px 0px;
  }
  .v-date-picker-month {
    background-color: black !important;
  }

  .v-btn__content {
    text-transform: none !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px !important;
  }

  .v-chip__close {
    background-color: transparent;
    color: white;
    position: relative;
    top: 1px;
    font-size: 15px !important;
  }

  .v-chip__close:hover {
    background-color: transparent;
  }

  .v-field .v-chip {
    color: #fff;
    background-color: #5f5f5f !important;
    font-size: 13px !important;
    font-weight: 400;
  }

  .v-field__outline__notch {
    &::before {
      display: inline-block;
    }

    &::after {
      display: inline-block;
    }
  }
}

.button {
  height: 45px !important;
}

.text-danger {
  color: #b00020;
}
.disabled-btn {
  pointer-events: none;
  opacity: 0.5;
}
</style>
