<template>
  <div class="v-card-header d-flex mb-7 ml-2">
    <h4 class="mb-0">Dashboard Cigarra</h4>
  </div>

  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-6">
      <IframeCard cardTitle="Posição de Chaves" :iframeSrc="buildGrafanaCigarraPanelUrl(6)"></IframeCard>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6">
      <IframeCard cardTitle="Nível de Sinal" :iframeSrc="buildGrafanaCigarraPanelUrl(8)"></IframeCard>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-6">
      <IframeCard cardTitle="Tecnologias" :iframeSrc="buildGrafanaCigarraPanelUrl(3)"></IframeCard>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6">
      <IframeCard cardTitle="Nível de Bateria" :iframeSrc="buildGrafanaCigarraPanelUrl(2)"></IframeCard>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <v-card class="mb-25 audienceOverview-box pingo-card">
        <div class="v-card-header border-bottom">
          <h5 class="mb-0">Dispositivos</h5>
        </div>

        <div class="dashboard">
          <iframe :src="buildGrafanaCigarraPanelUrl(4)" frameborder="0" width="100%" height="400px"></iframe>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import IframeCard from './component/IframeCard.vue';
import { buildGrafanaCigarraPanelUrl } from '../../utils/grafana';

export default defineComponent({
  name: 'CigarraView',
  components: { IframeCard },
  data() {
    return {
      buildGrafanaCigarraPanelUrl,
    };
  },
});
</script>
