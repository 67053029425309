<template>
  <v-col cols="12" lg="12" md="12">
    <div class="v-card-header border-bottom d-flex align-items-center mb-30">
      <h5 class="mb-0 mr-3">Aplique os filtros para visualizar os dados do equipamento</h5>

      <v-tooltip
        text="Os gráficos serão atualizados automaticamente conforme os filtros forem aplicados"
        location="bottom"
      >
        <template v-slot:activator="{ props }">
          <i v-bind="props" class="ri-information-fill"></i>
        </template>
      </v-tooltip>
    </div>
    <v-card fluid class="pingo-card mb-6">
      <v-row class="row d-flex align-items-center">
        <v-col cols="5" lg="5" md="5">
          <MultiSelect
            v-model="filters.client"
            :itemsList="clients"
            :itemValue="'client_id'"
            :itemTitle="'client_name'"
            :id="'client'"
            :label="'Cliente'"
            :density="'comfortable'"
            :placeholder="'Selecione os clientes'"
            :noDataText="'Nenhum cliente encontrado'"
            :multiple="false"
            :currentPage="currentClientPage"
            :totalPages="totalClientPages"
            @update:search="onSearch"
            @load-more-items="loadMoreClients"
          />
        </v-col>

        <v-col cols="5" lg="5" md="5">
          <MultiSelect
            v-model="filters.deviceId"
            :itemsList="deviceListByType"
            :itemValue="'device_id'"
            :itemTitle="'device_uuid'"
            :id="'devices'"
            :label="'Dispositivo'"
            :placeholder="'Dispositivos'"
            :noDataText="'Nenhum resultado encontrado!'"
            :multiple="false"
            :density="'comfortable'"
            :currentPage="currentDevicePageByType"
            :totalPages="totalDevicePagesByType"
            @update:search="searchDevicesByType"
            @load-more-items="loadMoreDevicesByType"
          />
        </v-col>
        <v-col cols="2" lg="2" md="2" class="d-flex align-items-center justify-content-center">
          <v-btn color="#48bb78" :disabled="!canApplyFilters" variant="tonal" @click="applyFilters">Aplicar</v-btn>
        </v-col>
      </v-row>
    </v-card>

    <div v-if="showGraphs">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-4">
          <IframeCard
            cardTitle="Vida útil restante"
            :iframeSrc="buildGrafanaQeeTrafoPanelUrl(14, appliedFilters.deviceId, appliedFilters.client)"
          ></IframeCard>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <IframeCard
            cardTitle="Temperatura do topo do óleo"
            :iframeSrc="buildGrafanaQeeTrafoPanelUrl(13, appliedFilters.deviceId, appliedFilters.client)"
          />
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <IframeCard
            cardTitle="Temperatura"
            :iframeSrc="buildGrafanaQeeTrafoPanelUrl(4, appliedFilters.deviceId, appliedFilters.client)"
          ></IframeCard>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 min-iframe">
          <IframeCard
            cardTitle="Tensão"
            :iframeSrc="buildGrafanaQeeTrafoPanelUrl(5, appliedFilters.deviceId, appliedFilters.client)"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 min-iframe">
          <IframeCard
            cardTitle="Corrente"
            :iframeSrc="buildGrafanaQeeTrafoPanelUrl(6, appliedFilters.deviceId, appliedFilters.client)"
          ></IframeCard>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 min-iframe">
          <IframeCard
            cardTitle="Fator de potência"
            :iframeSrc="buildGrafanaQeeTrafoPanelUrl(8, appliedFilters.deviceId, appliedFilters.client)"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 min-iframe">
          <IframeCard
            cardTitle="THD de Corrente"
            :iframeSrc="buildGrafanaQeeTrafoPanelUrl(9, appliedFilters.deviceId, appliedFilters.client)"
          ></IframeCard>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 min-iframe">
          <IframeCard
            cardTitle="THD de Tensão"
            :iframeSrc="buildGrafanaQeeTrafoPanelUrl(10, appliedFilters.deviceId, appliedFilters.client)"
          ></IframeCard>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 min-iframe">
          <IframeCard
            cardTitle="Carga"
            :iframeSrc="buildGrafanaQeeTrafoPanelUrl(7, appliedFilters.deviceId, appliedFilters.client)"
          ></IframeCard>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 min-iframe">
          <IframeCard
            cardTitle="Potência"
            :iframeSrc="buildGrafanaQeeTrafoPanelUrl(11, appliedFilters.deviceId, appliedFilters.client)"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 min-iframe">
          <IframeCard
            cardTitle="Energia acumulada"
            :iframeSrc="buildGrafanaQeeTrafoPanelUrl(12, appliedFilters.deviceId, appliedFilters.client)"
          ></IframeCard>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 min-iframe">
          <IframeCard
            cardTitle="Dispositivo"
            :iframeSrc="buildGrafanaQeeTrafoPanelUrl(15, appliedFilters.deviceId, appliedFilters.client)"
          ></IframeCard>
        </div>
      </div>
    </div>
  </v-col>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { buildGrafanaQeeTrafoPanelUrl } from '../../utils/grafana';
import IframeCard from '../dashboard/component/IframeCard.vue';
import { useClients } from '@/utils/composable/useClients';
import MultiSelect from '@/components/common/MultiSelect.vue';
import { useDeviceByType } from '@/utils/composable/useDeviceByType';

export default defineComponent({
  name: 'QEETrafo',
  components: { IframeCard, MultiSelect },
  data() {
    return {
      deviceType: '',
      buildGrafanaQeeTrafoPanelUrl,
      step: 1,
      items: ['Cliente', 'Dispositivo', 'Dashbord'],
      filters: {
        client: null,
        deviceId: null,
      },
      appliedFilters: {
        client: null,
        deviceId: null,
      },
      showGraphs: false,
    };
  },
  methods: {
    applyFilters() {
      if (this.filters.client && this.filters.deviceId) {
        this.appliedFilters.client = this.filters.client;
        this.appliedFilters.deviceId = this.filters.deviceId;
        this.showGraphs = true;
      }
    },
  },
  computed: {
    canApplyFilters() {
      return (
        this.filters.client !== null &&
        this.filters.deviceId !== null &&
        (this.filters.client !== this.appliedFilters.client || this.filters.deviceId !== this.appliedFilters.deviceId)
      );
    },
  },
  setup() {
    const { clients, loadMoreClients, isClientsLoading, totalClientPages, currentClientPage, fetchClients, onSearch } =
      useClients();
    const {
      deviceListByType,
      loadMoreDevicesByType,
      isDeviceLoadingByType,
      currentDevicePageByType,
      totalDevicePagesByType,
      fetchDevicesByType,
      searchDevicesByType,
    } = useDeviceByType('7Trafo');

    return {
      clients,
      loadMoreClients,
      isClientsLoading,
      totalClientPages,
      currentClientPage,
      fetchClients,
      deviceListByType,
      loadMoreDevicesByType,
      isDeviceLoadingByType,
      currentDevicePageByType,
      totalDevicePagesByType,
      fetchDevicesByType,
      onSearch,
      searchDevicesByType,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep {
  .min-iframe {
    iframe {
      height: 70% !important;
    }

    .v-card.pingo-card {
      height: 410px !important;
    }
  }

  .v-stepper-header {
    box-shadow: none;
  }

  .ri-information-fill:before {
    color: #43444b9c;
    position: relative;
    right: 4px;
    top: 2px;
  }

  .v-field__outline__notch {
    &::before {
      display: inline-block;
    }

    &::after {
      display: inline-block;
    }
  }

  .v-btn--variant-tonal {
    background-color: var(--heraBlueColor);
    color: var(--whiteColor);
    text-transform: none;
    height: 48px !important;
    width: 100%;
    margin-left: 10px;

    span {
      color: white;
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
    }
  }

  .v-stepper-item--selected .v-stepper-item__avatar.v-avatar,
  .v-stepper-item--complete .v-stepper-item__avatar.v-avatar {
    background-color: var(--heraBlueColor);
    height: 60px;
    width: 60px;
  }
}
</style>
