import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, toDisplayString as _toDisplayString, mergeProps as _mergeProps, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "pingo-form-group without-icon" }
const _hoisted_3 = { class: "pingo-form-group without-icon" }
const _hoisted_4 = { class: "pingo-form-group without-icon" }
const _hoisted_5 = { class: "pingo-form-group without-icon" }
const _hoisted_6 = { class: "pingo-form-group without-icon" }
const _hoisted_7 = { class: "pingo-form-group without-icon" }
const _hoisted_8 = { class: "text-end" }
const _hoisted_9 = ["loading"]
const _hoisted_10 = { class: "d-flex align-items-flex-end justify-content-flex-end mb-30" }
const _hoisted_11 = { class: "pingo-table-content" }
const _hoisted_12 = { class: "pingo-table v-elevation-z8" }
const _hoisted_13 = { class: "pingo-table-content" }
const _hoisted_14 = { class: "title-text text-start" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "footer-table" }
const _hoisted_17 = { class: "d-flex align-items-center justify-content-center" }
const _hoisted_18 = { class: "text-end pt-2" }
const _hoisted_19 = { class: "d-flex align-items-flex-end justify-content-flex-end mb-30" }
const _hoisted_20 = { class: "pingo-table-content" }
const _hoisted_21 = { class: "pingo-table v-elevation-z8" }
const _hoisted_22 = { class: "pingo-table-content" }
const _hoisted_23 = { class: "title-text text-start" }
const _hoisted_24 = { class: "text-start" }
const _hoisted_25 = { class: "text-start" }
const _hoisted_26 = ["onClick"]
const _hoisted_27 = { class: "footer-table" }
const _hoisted_28 = { class: "d-flex align-items-center justify-content-center" }
const _hoisted_29 = { class: "text-end pt-2" }
const _hoisted_30 = { class: "w-100" }
const _hoisted_31 = { class: "pingo-form-group without-icon" }
const _hoisted_32 = { class: "pingo-form-group without-icon" }
const _hoisted_33 = { class: "pingo-form-group without-icon" }
const _hoisted_34 = { class: "pingo-form-group without-icon" }
const _hoisted_35 = { class: "w-100 pt-6" }
const _hoisted_36 = { class: "w-100 pt-6" }
const _hoisted_37 = { class: "pingo-form-group without-icon" }
const _hoisted_38 = { class: "w-100 pt-6" }
const _hoisted_39 = { class: "w-100 pt-6" }
const _hoisted_40 = { class: "pingo-form-group without-icon" }
const _hoisted_41 = { class: "pingo-form-group without-icon" }
const _hoisted_42 = { class: "pingo-form-group without-icon" }
const _hoisted_43 = { class: "text-end mt-30" }
const _hoisted_44 = ["loading"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_tabs_window_item = _resolveComponent("v-tabs-window-item")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")!
  const _component_ConfirmationDialog = _resolveComponent("ConfirmationDialog")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Editar cliente",
      mainTitle: "Editar cliente",
      class: "mb-20"
    }),
    _createVNode(_component_v_card, { class: "tabs-card" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tabs, {
          modelValue: _ctx.tab,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
          "align-tabs": "start"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_tab, { value: _ctx.TAB_INFO }, {
              default: _withCtx(() => _cache[33] || (_cache[33] = [
                _createTextVNode("Informações")
              ])),
              _: 1
            }, 8, ["value"]),
            _createVNode(_component_v_tab, { value: _ctx.TAB_ASSOCIATED_DEVICES }, {
              default: _withCtx(() => _cache[34] || (_cache[34] = [
                _createTextVNode("Dispositivos associados")
              ])),
              _: 1
            }, 8, ["value"]),
            _createVNode(_component_v_tab, { value: _ctx.TAB_ASSOCIATED_USERS }, {
              default: _withCtx(() => _cache[35] || (_cache[35] = [
                _createTextVNode("Usuários Associados")
              ])),
              _: 1
            }, 8, ["value"]),
            _createVNode(_component_v_tab, { value: _ctx.TAB_CONFIG_DEVICE }, {
              default: _withCtx(() => _cache[36] || (_cache[36] = [
                _createTextVNode("Gerenciamento de dispositivos")
              ])),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_v_card, { class: "mb-25 pingo-card" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tabs_window, {
          modelValue: _ctx.tab,
          "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.tab) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_tabs_window_item, { value: _ctx.TAB_INFO }, {
              default: _withCtx(() => [
                _createVNode(_component_v_container, { fluid: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "12",
                          md: "12"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, [
                              _createElementVNode("form", {
                                onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"])),
                                class: "form"
                              }, [
                                _createVNode(_component_v_row, { class: "pb-0" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { class: "col-lg-4 col-md-4 col-sm-4" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_2, [
                                          _cache[37] || (_cache[37] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Nome do Cliente *", -1)),
                                          _createVNode(_component_v_text_field, {
                                            type: "text",
                                            placeholder: "Nome do Cliente",
                                            modelValue: _ctx.client.name,
                                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.client.name) = $event)),
                                            autocomplete: "off",
                                            required: ""
                                          }, null, 8, ["modelValue"])
                                        ])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, { class: "col-lg-5 col-md-5 col-sm-5" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_3, [
                                          _cache[38] || (_cache[38] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Endereço", -1)),
                                          _createVNode(_component_v_text_field, {
                                            type: "text",
                                            placeholder: "Endereço",
                                            modelValue: _ctx.client.street,
                                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.client.street) = $event)),
                                            autocomplete: "off"
                                          }, null, 8, ["modelValue"])
                                        ])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, { class: "col-lg-3 col-md-3 col-sm-3" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_4, [
                                          _cache[39] || (_cache[39] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Cidade", -1)),
                                          _createVNode(_component_v_text_field, {
                                            type: "text",
                                            placeholder: "Cidade",
                                            modelValue: _ctx.client.city,
                                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.client.city) = $event))
                                          }, null, 8, ["modelValue"])
                                        ])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, { class: "col-lg-4 col-md-4 col-sm-4" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_5, [
                                          _cache[40] || (_cache[40] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Estado", -1)),
                                          _createVNode(_component_v_text_field, {
                                            type: "text",
                                            placeholder: "Estado",
                                            modelValue: _ctx.client.state,
                                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.client.state) = $event))
                                          }, null, 8, ["modelValue"])
                                        ])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, { class: "col-lg-4 col-md-4 col-sm-4" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_6, [
                                          _cache[41] || (_cache[41] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "CEP", -1)),
                                          _createVNode(_component_v_text_field, {
                                            type: "text",
                                            placeholder: "CEP",
                                            modelValue: _ctx.client.zipcode,
                                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.client.zipcode) = $event))
                                          }, null, 8, ["modelValue"])
                                        ])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, { class: "col-lg-4 col-md-4 col-sm-4" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_7, [
                                          _cache[42] || (_cache[42] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "País", -1)),
                                          _createVNode(_component_v_text_field, {
                                            type: "text",
                                            placeholder: "País",
                                            modelValue: _ctx.client.country,
                                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.client.country) = $event))
                                          }, null, 8, ["modelValue"])
                                        ])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createElementVNode("div", _hoisted_8, [
                                  _createElementVNode("button", {
                                    type: "button",
                                    onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleBackClick && _ctx.handleBackClick(...args))),
                                    class: "default-btn elevation-0 ps-30 pe-30 bg-grey mr-2"
                                  }, " Voltar "),
                                  _createElementVNode("button", {
                                    type: "submit",
                                    loading: _ctx.loading,
                                    class: _normalizeClass(["default-btn ps-30 pe-30", { 'disabled-btn': !_ctx.hasChangesClient }])
                                  }, " Salvar alterações ", 10, _hoisted_9)
                                ])
                              ], 32)
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"]),
            _createVNode(_component_v_tabs_window_item, { value: _ctx.TAB_ASSOCIATED_DEVICES }, {
              default: _withCtx(() => [
                _createVNode(_component_v_container, { fluid: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "12",
                          md: "12"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_10, [
                              _createVNode(_component_MultiSelect, {
                                modelValue: _ctx.assignedDevices,
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.assignedDevices) = $event)),
                                itemsList: _ctx.devicesList,
                                itemValue: 'device_id',
                                itemTitle: 'device_uuid',
                                id: 'devices',
                                label: 'Dispositivos',
                                placeholder: 'Dispositivos',
                                noDataText: _ctx.NO_DATA_AVAILABLE.DEVICE,
                                currentPage: _ctx.currentDevicePage,
                                totalPages: _ctx.totalDevicePages,
                                "onUpdate:search": _ctx.onDeviceSearch,
                                onLoadMoreItems: _ctx.loadMoreDevices
                              }, null, 8, ["modelValue", "itemsList", "noDataText", "currentPage", "totalPages", "onUpdate:search", "onLoadMoreItems"]),
                              _createElementVNode("div", null, [
                                _createVNode(_component_v_btn, {
                                  type: "submit",
                                  loading: _ctx.loadingAddButton,
                                  disabled: _ctx.loadingAddButton,
                                  onClick: _ctx.associateDevicesWithClient,
                                  class: "success-devices elevation-0 text-end"
                                }, {
                                  default: _withCtx(() => _cache[43] || (_cache[43] = [
                                    _createTextVNode(" Adicionar ")
                                  ])),
                                  _: 1
                                }, 8, ["loading", "disabled", "onClick"])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_11, [
                              _createElementVNode("div", _hoisted_12, [
                                _createElementVNode("div", _hoisted_13, [
                                  _createVNode(_component_DataTable, {
                                    headers: _ctx.HEADER_DEVICES_BY_CLIENT,
                                    items: _ctx.devicesByClient,
                                    loading: _ctx.loading
                                  }, {
                                    item: _withCtx(({ item }) => [
                                      _createElementVNode("tr", null, [
                                        _createElementVNode("td", _hoisted_14, _toDisplayString(item.type_name), 1),
                                        _createElementVNode("td", null, _toDisplayString(item.device_uuid), 1),
                                        _createElementVNode("td", null, _toDisplayString(item.operational_number), 1),
                                        _createElementVNode("td", null, _toDisplayString(item.line_number), 1),
                                        _createElementVNode("td", null, [
                                          _createVNode(_component_v_menu, null, {
                                            activator: _withCtx(({ props }) => [
                                              _createElementVNode("button", _mergeProps({ type: "button" }, props, { class: "position-relative dot-btn p-0 bg-transparent border-none" }), _cache[44] || (_cache[44] = [
                                                _createElementVNode("i", { class: "ri-more-fill" }, null, -1)
                                              ]), 16)
                                            ]),
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list, { class: "dotMenu two monthMenu" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("button", {
                                                    type: "button",
                                                    class: "bg-transparent border-none position-relative",
                                                    onClick: ($event: any) => (_ctx.removeDeviceFromClient(item.id))
                                                  }, _cache[45] || (_cache[45] = [
                                                    _createElementVNode("i", { class: "ri-delete-bin-line" }, null, -1),
                                                    _createTextVNode(" Remover ")
                                                  ]), 8, _hoisted_15)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ])
                                      ])
                                    ]),
                                    bottom: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_16, [
                                        _createElementVNode("div", _hoisted_17, [
                                          _createElementVNode("div", _hoisted_18, [
                                            _createVNode(_component_v_pagination, {
                                              modelValue: _ctx.currentPageTableDevices,
                                              "onUpdate:modelValue": [
                                                _cache[10] || (_cache[10] = ($event: any) => ((_ctx.currentPageTableDevices) = $event)),
                                                _ctx.onPageChangeDevices
                                              ],
                                              length: _ctx.lastDeviceByClientPage,
                                              "next-icon": "mdi-chevron-right",
                                              "prev-icon": "mdi-chevron-left",
                                              "total-visible": _ctx.totalPagesDevices,
                                              color: "#7a7e87"
                                            }, null, 8, ["modelValue", "length", "total-visible", "onUpdate:modelValue"])
                                          ])
                                        ])
                                      ])
                                    ]),
                                    _: 1
                                  }, 8, ["headers", "items", "loading"])
                                ])
                              ])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"]),
            _createVNode(_component_v_tabs_window_item, { value: _ctx.TAB_ASSOCIATED_USERS }, {
              default: _withCtx(() => [
                _createVNode(_component_v_container, { fluid: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "12",
                          md: "12"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_19, [
                              _createVNode(_component_MultiSelect, {
                                modelValue: _ctx.assignedUsers,
                                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.assignedUsers) = $event)),
                                itemsList: _ctx.usersList,
                                itemValue: 'user_id',
                                itemTitle: 'user_name',
                                id: 'users',
                                label: 'Usuários',
                                placeholder: 'Usuários',
                                noDataText: _ctx.NO_DATA_AVAILABLE.USER,
                                currentPage: _ctx.currentUserPage,
                                totalPages: _ctx.totalUserPages,
                                "onUpdate:search": _ctx.onUserSearch,
                                onLoadMoreItems: _ctx.loadMoreUsers
                              }, null, 8, ["modelValue", "itemsList", "noDataText", "currentPage", "totalPages", "onUpdate:search", "onLoadMoreItems"]),
                              _createElementVNode("div", null, [
                                _createVNode(_component_v_btn, {
                                  type: "submit",
                                  loading: _ctx.loadingAddButton,
                                  disabled: _ctx.loadingAddButton,
                                  onClick: _ctx.associateUsersWithClient,
                                  class: "success-devices elevation-0 text-end"
                                }, {
                                  default: _withCtx(() => _cache[46] || (_cache[46] = [
                                    _createTextVNode(" Adicionar ")
                                  ])),
                                  _: 1
                                }, 8, ["loading", "disabled", "onClick"])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_20, [
                              _createElementVNode("div", _hoisted_21, [
                                _createElementVNode("div", _hoisted_22, [
                                  _createVNode(_component_DataTable, {
                                    headers: _ctx.HEADER_USERS_BY_CLIENT,
                                    items: _ctx.usersByClient,
                                    loading: _ctx.loading,
                                    "server-items-length": _ctx.usersByClient.length,
                                    page: _ctx.currentPageTableUsers
                                  }, {
                                    item: _withCtx(({ item }) => [
                                      _createElementVNode("tr", null, [
                                        _createElementVNode("td", _hoisted_23, _toDisplayString(item.name), 1),
                                        _createElementVNode("td", _hoisted_24, _toDisplayString(item.email), 1),
                                        _createElementVNode("td", _hoisted_25, [
                                          _createVNode(_component_v_menu, null, {
                                            activator: _withCtx(({ props }) => [
                                              _createElementVNode("button", _mergeProps({ type: "button" }, props, { class: "position-relative dot-btn p-0 bg-transparent border-none" }), _cache[47] || (_cache[47] = [
                                                _createElementVNode("i", { class: "ri-more-fill" }, null, -1)
                                              ]), 16)
                                            ]),
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list, { class: "dotMenu two monthMenu" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("button", {
                                                    type: "button",
                                                    class: "bg-transparent border-none position-relative",
                                                    onClick: ($event: any) => (_ctx.removeUserFromClient(item.id))
                                                  }, _cache[48] || (_cache[48] = [
                                                    _createElementVNode("i", { class: "ri-delete-bin-line" }, null, -1),
                                                    _createTextVNode(" Remover ")
                                                  ]), 8, _hoisted_26)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ])
                                      ])
                                    ]),
                                    bottom: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_27, [
                                        _createElementVNode("div", _hoisted_28, [
                                          _createElementVNode("div", _hoisted_29, [
                                            _createVNode(_component_v_pagination, {
                                              modelValue: _ctx.currentPageTableUsers,
                                              "onUpdate:modelValue": [
                                                _cache[12] || (_cache[12] = ($event: any) => ((_ctx.currentPageTableUsers) = $event)),
                                                _ctx.onPageChangeUsers
                                              ],
                                              length: _ctx.lastUserByClientPage,
                                              "next-icon": "mdi-chevron-right",
                                              "prev-icon": "mdi-chevron-left",
                                              "total-visible": _ctx.totalPagesUsers,
                                              color: "#7a7e87"
                                            }, null, 8, ["modelValue", "length", "total-visible", "onUpdate:modelValue"])
                                          ])
                                        ])
                                      ])
                                    ]),
                                    _: 1
                                  }, 8, ["headers", "items", "loading", "server-items-length", "page"])
                                ])
                              ])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"]),
            _createVNode(_component_v_tabs_window_item, { value: _ctx.TAB_CONFIG_DEVICE }, {
              default: _withCtx(() => [
                _createVNode(_component_v_container, {
                  fluid: "",
                  class: "p-30"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_30, [
                          _createVNode(_component_v_col, {
                            cols: "12",
                            lg: "12",
                            md: "12"
                          }, {
                            default: _withCtx(() => [
                              _cache[49] || (_cache[49] = _createElementVNode("h5", null, "Localização", -1)),
                              _createVNode(_component_v_divider, { class: "pb-3" })
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "6",
                          lg: "6",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_31, [
                              _cache[50] || (_cache[50] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Porta TCP", -1)),
                              _createVNode(_component_v_text_field, {
                                type: "number",
                                placeholder: "Digite a porta TCP",
                                modelValue: _ctx.deviceSettings.tcp_port,
                                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.deviceSettings.tcp_port) = $event)),
                                modelModifiers: { number: true }
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "6",
                          lg: "6",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_32, [
                              _cache[51] || (_cache[51] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Porta UDP", -1)),
                              _createVNode(_component_v_text_field, {
                                type: "number",
                                placeholder: "Digite a porta UDP",
                                modelValue: _ctx.deviceSettings.upd_port,
                                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.deviceSettings.upd_port) = $event)),
                                modelModifiers: { number: true }
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "6",
                          lg: "6",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_33, [
                              _cache[52] || (_cache[52] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Endereço Master", -1)),
                              _createVNode(_component_v_text_field, {
                                type: "number",
                                placeholder: "Digite o endereço Master",
                                modelValue: _ctx.deviceSettings.master_address,
                                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.deviceSettings.master_address) = $event)),
                                modelModifiers: { number: true }
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "6",
                          lg: "6",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_34, [
                              _cache[53] || (_cache[53] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Endereço Slave", -1)),
                              _createVNode(_component_v_text_field, {
                                type: "number",
                                placeholder: "Digite o endereço Slave",
                                modelValue: _ctx.deviceSettings.slave_address,
                                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.deviceSettings.slave_address) = $event)),
                                modelModifiers: { number: true }
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "12",
                          md: "12"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _cache[54] || (_cache[54] = _createElementVNode("label", { class: "d-block pb-3 fw-semibold gray-color" }, "Filtro de Endereço", -1)),
                              _createVNode(_component_v_radio_group, {
                                modelValue: _ctx.deviceSettings.filter_address_enabled,
                                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.deviceSettings.filter_address_enabled) = $event)),
                                color: "#35ab6a"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_radio, {
                                    label: "Ativo",
                                    value: true
                                  }),
                                  _createVNode(_component_v_radio, {
                                    label: "Desativado",
                                    value: false
                                  })
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_35, [
                          _createVNode(_component_v_col, {
                            cols: "12",
                            lg: "12",
                            md: "12"
                          }, {
                            default: _withCtx(() => [
                              _cache[55] || (_cache[55] = _createElementVNode("h5", null, "Time Stamp", -1)),
                              _createVNode(_component_v_divider, { class: "pb-3" })
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "4",
                          lg: "4",
                          md: "4"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _cache[56] || (_cache[56] = _createElementVNode("label", { class: "d-block pb-3 fw-semibold gray-color mr-3" }, "Entradas analógicas", -1)),
                              _createVNode(_component_v_radio_group, {
                                modelValue: _ctx.deviceSettings.ts_analog_in_enabled,
                                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.deviceSettings.ts_analog_in_enabled) = $event)),
                                color: "#35ab6a"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_radio, {
                                    label: "Ativo",
                                    value: true
                                  }),
                                  _createVNode(_component_v_radio, {
                                    label: "Desativado",
                                    value: false
                                  })
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "4",
                          lg: "4",
                          md: "4"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _cache[57] || (_cache[57] = _createElementVNode("label", { class: "d-block pb-3 fw-semibold gray-color" }, "Entradas digitais", -1)),
                              _createVNode(_component_v_radio_group, {
                                modelValue: _ctx.deviceSettings.ts_digital_in_enabled,
                                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.deviceSettings.ts_digital_in_enabled) = $event)),
                                color: "#35ab6a"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_radio, {
                                    label: "Ativo",
                                    value: true
                                  }),
                                  _createVNode(_component_v_radio, {
                                    label: "Desativado",
                                    value: false
                                  })
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "4",
                          lg: "4",
                          md: "4"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _cache[58] || (_cache[58] = _createElementVNode("label", { class: "d-block pb-3 fw-semibold gray-color" }, "Saídas digitais", -1)),
                              _createVNode(_component_v_radio_group, {
                                modelValue: _ctx.deviceSettings.ts_digital_out_enabled,
                                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.deviceSettings.ts_digital_out_enabled) = $event)),
                                color: "#35ab6a"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_radio, {
                                    label: "Ativo",
                                    value: true
                                  }),
                                  _createVNode(_component_v_radio, {
                                    label: "Desativado",
                                    value: false
                                  })
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_36, [
                          _createVNode(_component_v_col, {
                            cols: "12",
                            lg: "12",
                            md: "12"
                          }, {
                            default: _withCtx(() => [
                              _cache[59] || (_cache[59] = _createElementVNode("h5", null, "Controle de tempo", -1)),
                              _createVNode(_component_v_divider, { class: "pb-3" })
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "4",
                          lg: "4",
                          md: "4"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_37, [
                              _cache[60] || (_cache[60] = _createElementVNode("label", { class: "d-block fw-semibold gray-color" }, "Tempo", -1)),
                              _createVNode(_component_v_text_field, {
                                type: "number",
                                placeholder: "Tempo",
                                modelValue: _ctx.deviceSettings.ts_sync_frequency,
                                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.deviceSettings.ts_sync_frequency) = $event)),
                                modelModifiers: { number: true }
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "4",
                          lg: "4",
                          md: "4"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _cache[61] || (_cache[61] = _createElementVNode("label", { class: "d-block pb-3 fw-semibold gray-color" }, "Estado", -1)),
                              _createVNode(_component_v_radio_group, {
                                inline: "",
                                modelValue: _ctx.deviceSettings.ts_sync_enabled,
                                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.deviceSettings.ts_sync_enabled) = $event)),
                                color: "#35ab6a"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_radio, {
                                    label: "Ativo",
                                    value: true
                                  }),
                                  _createVNode(_component_v_radio, {
                                    label: "Desativado",
                                    value: false
                                  })
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_38, [
                          _createVNode(_component_v_col, {
                            cols: "12",
                            lg: "12",
                            md: "12"
                          }, {
                            default: _withCtx(() => [
                              _cache[62] || (_cache[62] = _createElementVNode("h5", null, "Solicitação", -1)),
                              _createVNode(_component_v_divider, { class: "pb-3" })
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "4",
                          lg: "4",
                          md: "4"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _cache[63] || (_cache[63] = _createElementVNode("label", { class: "d-block pb-3 fw-semibold gray-color" }, "Estado", -1)),
                              _createVNode(_component_v_radio_group, {
                                modelValue: _ctx.deviceSettings.unsolicted_enabled,
                                "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.deviceSettings.unsolicted_enabled) = $event)),
                                color: "#35ab6a"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_radio, {
                                    label: "Ativo",
                                    value: true
                                  }),
                                  _createVNode(_component_v_radio, {
                                    label: "Desativado",
                                    value: false
                                  })
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_39, [
                          _createVNode(_component_v_col, {
                            cols: "12",
                            lg: "12",
                            md: "12"
                          }, {
                            default: _withCtx(() => [
                              _cache[64] || (_cache[64] = _createElementVNode("h5", null, "Número mínimo de eventos para envio", -1)),
                              _createVNode(_component_v_divider, { class: "pb-3" })
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "4",
                          lg: "4",
                          md: "4"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_40, [
                              _cache[65] || (_cache[65] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Classe 1", -1)),
                              _createVNode(_component_v_text_field, {
                                type: "number",
                                placeholder: "Classe 1",
                                modelValue: _ctx.deviceSettings.class_one_min_events,
                                "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.deviceSettings.class_one_min_events) = $event)),
                                modelModifiers: { number: true }
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "4",
                          lg: "4",
                          md: "4"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_41, [
                              _cache[66] || (_cache[66] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Classe 2", -1)),
                              _createVNode(_component_v_text_field, {
                                type: "number",
                                placeholder: "Classe 2",
                                modelValue: _ctx.deviceSettings.class_two_min_events,
                                "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.deviceSettings.class_two_min_events) = $event)),
                                modelModifiers: { number: true }
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "4",
                          lg: "4",
                          md: "4"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_42, [
                              _cache[67] || (_cache[67] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Classe 3", -1)),
                              _createVNode(_component_v_text_field, {
                                type: "number",
                                placeholder: "Classe 3",
                                modelValue: _ctx.deviceSettings.class_three_min_events,
                                "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.deviceSettings.class_three_min_events) = $event)),
                                modelModifiers: { number: true }
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_43, [
                      _createElementVNode("button", {
                        type: "button",
                        onClick: _cache[27] || (_cache[27] = 
//@ts-ignore
(...args) => (_ctx.handleSettingsBackClick && _ctx.handleSettingsBackClick(...args))),
                        class: "default-btn elevation-0 ps-30 pe-30 bg-grey mr-2"
                      }, " Voltar "),
                      _createElementVNode("button", {
                        type: "submit",
                        loading: _ctx.loadingSettings,
                        class: _normalizeClass([{ 'disabled-btn': !_ctx.hasChangesSettings }, "default-btn ps-30 pe-30"]),
                        onClick: _cache[28] || (_cache[28] = 
//@ts-ignore
(...args) => (_ctx.handleSettingsSave && _ctx.handleSettingsSave(...args)))
                      }, " Salvar alterações ", 10, _hoisted_44)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_ConfirmationDialog, {
          visible: _ctx.confirmDialog,
          "onUpdate:visible": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.confirmDialog) = $event)),
          title: "Confirmação",
          message: "Existem alterações não salvas. Tem certeza de que deseja deseja voltar?",
          onConfirm: _ctx.confirmBack
        }, null, 8, ["visible", "onConfirm"])
      ]),
      _: 1
    }),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.isSnackbarVisible,
      "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.isSnackbarVisible) = $event)),
      color: "#35ab6a",
      location: "top right"
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_icon, {
          size: "20",
          color: "white",
          onClick: _cache[31] || (_cache[31] = ($event: any) => (_ctx.isSnackbarVisible = false)),
          class: "mr-3"
        }, {
          default: _withCtx(() => _cache[68] || (_cache[68] = [
            _createTextVNode("mdi-close")
          ])),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.message) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}