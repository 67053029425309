import { createElementVNode as _createElementVNode, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-card-header border-bottom d-flex align-items-center mb-30" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-lg-4 col-md-4 col-sm-4" }
const _hoisted_5 = { class: "col-lg-4 col-md-4 col-sm-4" }
const _hoisted_6 = { class: "col-lg-4 col-md-4 col-sm-4" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-lg-6 col-md-6 col-sm-6 min-iframe" }
const _hoisted_9 = { class: "col-lg-6 col-md-6 col-sm-6 min-iframe" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-lg-6 col-md-6 col-sm-6 min-iframe" }
const _hoisted_12 = { class: "col-lg-6 col-md-6 col-sm-6 min-iframe" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-lg-6 col-md-6 col-sm-6 min-iframe" }
const _hoisted_15 = { class: "col-lg-6 col-md-6 col-sm-6 min-iframe" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-lg-6 col-md-6 col-sm-6 min-iframe" }
const _hoisted_18 = { class: "col-lg-6 col-md-6 col-sm-6 min-iframe" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-lg-12 col-md-12 col-sm-12 min-iframe" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_IframeCard = _resolveComponent("IframeCard")!

  return (_openBlock(), _createBlock(_component_v_col, {
    cols: "12",
    lg: "12",
    md: "12"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[2] || (_cache[2] = _createElementVNode("h5", { class: "mb-0 mr-3" }, "Aplique os filtros para visualizar os dados do equipamento", -1)),
        _createVNode(_component_v_tooltip, {
          text: "Os gráficos serão atualizados automaticamente conforme os filtros forem aplicados",
          location: "bottom"
        }, {
          activator: _withCtx(({ props }) => [
            _createElementVNode("i", _mergeProps(props, { class: "ri-information-fill" }), null, 16)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_v_card, {
        fluid: "",
        class: "pingo-card mb-6"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { class: "row d-flex align-items-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "5",
                lg: "5",
                md: "5"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MultiSelect, {
                    modelValue: _ctx.filters.client,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters.client) = $event)),
                    itemsList: _ctx.clients,
                    itemValue: 'client_id',
                    itemTitle: 'client_name',
                    id: 'client',
                    label: 'Cliente',
                    density: 'comfortable',
                    placeholder: 'Selecione os clientes',
                    noDataText: 'Nenhum cliente encontrado',
                    multiple: false,
                    currentPage: _ctx.currentClientPage,
                    totalPages: _ctx.totalClientPages,
                    "onUpdate:search": _ctx.onSearch,
                    onLoadMoreItems: _ctx.loadMoreClients
                  }, null, 8, ["modelValue", "itemsList", "currentPage", "totalPages", "onUpdate:search", "onLoadMoreItems"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "5",
                lg: "5",
                md: "5"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MultiSelect, {
                    modelValue: _ctx.filters.deviceId,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.deviceId) = $event)),
                    itemsList: _ctx.deviceListByType,
                    itemValue: 'device_id',
                    itemTitle: 'device_uuid',
                    id: 'devices',
                    label: 'Dispositivo',
                    placeholder: 'Dispositivos',
                    noDataText: 'Nenhum resultado encontrado!',
                    multiple: false,
                    density: 'comfortable',
                    currentPage: _ctx.currentDevicePageByType,
                    totalPages: _ctx.totalDevicePagesByType,
                    "onUpdate:search": _ctx.searchDevicesByType,
                    onLoadMoreItems: _ctx.loadMoreDevicesByType
                  }, null, 8, ["modelValue", "itemsList", "currentPage", "totalPages", "onUpdate:search", "onLoadMoreItems"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "2",
                lg: "2",
                md: "2",
                class: "d-flex align-items-center justify-content-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    color: "#48bb78",
                    disabled: !_ctx.canApplyFilters,
                    variant: "tonal",
                    onClick: _ctx.applyFilters
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("Aplicar")
                    ])),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.showGraphs)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_IframeCard, {
                  cardTitle: "Vida útil restante",
                  iframeSrc: _ctx.buildGrafanaQeeTrafoPanelUrl(14, _ctx.appliedFilters.deviceId, _ctx.appliedFilters.client)
                }, null, 8, ["iframeSrc"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_IframeCard, {
                  cardTitle: "Temperatura do topo do óleo",
                  iframeSrc: _ctx.buildGrafanaQeeTrafoPanelUrl(13, _ctx.appliedFilters.deviceId, _ctx.appliedFilters.client)
                }, null, 8, ["iframeSrc"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_IframeCard, {
                  cardTitle: "Temperatura",
                  iframeSrc: _ctx.buildGrafanaQeeTrafoPanelUrl(4, _ctx.appliedFilters.deviceId, _ctx.appliedFilters.client)
                }, null, 8, ["iframeSrc"])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_IframeCard, {
                  cardTitle: "Tensão",
                  iframeSrc: _ctx.buildGrafanaQeeTrafoPanelUrl(5, _ctx.appliedFilters.deviceId, _ctx.appliedFilters.client)
                }, null, 8, ["iframeSrc"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_IframeCard, {
                  cardTitle: "Corrente",
                  iframeSrc: _ctx.buildGrafanaQeeTrafoPanelUrl(6, _ctx.appliedFilters.deviceId, _ctx.appliedFilters.client)
                }, null, 8, ["iframeSrc"])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_IframeCard, {
                  cardTitle: "Fator de potência",
                  iframeSrc: _ctx.buildGrafanaQeeTrafoPanelUrl(8, _ctx.appliedFilters.deviceId, _ctx.appliedFilters.client)
                }, null, 8, ["iframeSrc"])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_IframeCard, {
                  cardTitle: "THD de Corrente",
                  iframeSrc: _ctx.buildGrafanaQeeTrafoPanelUrl(9, _ctx.appliedFilters.deviceId, _ctx.appliedFilters.client)
                }, null, 8, ["iframeSrc"])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_IframeCard, {
                  cardTitle: "THD de Tensão",
                  iframeSrc: _ctx.buildGrafanaQeeTrafoPanelUrl(10, _ctx.appliedFilters.deviceId, _ctx.appliedFilters.client)
                }, null, 8, ["iframeSrc"])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_IframeCard, {
                  cardTitle: "Carga",
                  iframeSrc: _ctx.buildGrafanaQeeTrafoPanelUrl(7, _ctx.appliedFilters.deviceId, _ctx.appliedFilters.client)
                }, null, 8, ["iframeSrc"])
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_IframeCard, {
                  cardTitle: "Potência",
                  iframeSrc: _ctx.buildGrafanaQeeTrafoPanelUrl(11, _ctx.appliedFilters.deviceId, _ctx.appliedFilters.client)
                }, null, 8, ["iframeSrc"])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_IframeCard, {
                  cardTitle: "Energia acumulada",
                  iframeSrc: _ctx.buildGrafanaQeeTrafoPanelUrl(12, _ctx.appliedFilters.deviceId, _ctx.appliedFilters.client)
                }, null, 8, ["iframeSrc"])
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_IframeCard, {
                  cardTitle: "Dispositivo",
                  iframeSrc: _ctx.buildGrafanaQeeTrafoPanelUrl(15, _ctx.appliedFilters.deviceId, _ctx.appliedFilters.client)
                }, null, 8, ["iframeSrc"])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}