import { ref, onMounted } from 'vue';
import { getDevices } from '@/services/devices';

export function useDeviceByType(deviceType: string) {
  const deviceListByType = ref<any[]>([]);
  const totalDevicePagesByType = ref<number>(0);
  const currentDevicePageByType = ref<number>(1);
  const isDeviceLoadingByType = ref<boolean>(false);

  const fetchDevicesByType = async (page = 1, searchTerm = '') => {
    isDeviceLoadingByType.value = true;
    try {
      const { totalPages, filteredRecord } = await getDevices({
        page: page,
        search: searchTerm,
        deviceType: deviceType,
      });
      totalDevicePagesByType.value = totalPages;
      currentDevicePageByType.value = page;

      return filteredRecord.map((device) => {
        return {
          device_uuid: device.device_uuid,
          device_id: device.id,
        };
      });
    } catch (error) {
      console.error('Failed to load devices by type:', error);
      return [];
    } finally {
      isDeviceLoadingByType.value = false;
    }
  };

  const loadMoreDevicesByType = async ({ done }) => {
    if (currentDevicePageByType.value < totalDevicePagesByType.value) {
      const newDevices = await fetchDevicesByType(currentDevicePageByType.value + 1);

      newDevices.forEach((newDevice) => {
        const exists = deviceListByType.value.some((device) => device.device_id === newDevice.device_id);
        if (!exists) {
          deviceListByType.value.push(newDevice);
        }
      });
    }
    done();
  };

  const searchDevicesByType = async (searchTerm: string) => {
    if (!searchTerm) return;

    const termExists = deviceListByType.value.some(
      (device) => device.device_uuid && device.device_uuid.includes(searchTerm)
    );
    if (termExists) return;

    const searchedDevices = await fetchDevicesByType(1, searchTerm);
    const uniqueDevices = searchedDevices.filter(
      (searchedDevice) => !deviceListByType.value.some((device) => device.device_id === searchedDevice.device_id)
    );

    deviceListByType.value = [...uniqueDevices, ...deviceListByType.value];
  };

  onMounted(async () => {
    const initialDevices = await fetchDevicesByType();
    deviceListByType.value = initialDevices;
  });

  return {
    deviceListByType,
    loadMoreDevicesByType,
    isDeviceLoadingByType,
    currentDevicePageByType,
    totalDevicePagesByType,
    fetchDevicesByType,
    searchDevicesByType,
  };
}
