const ALIGN_CENTER = 'center' as const;
const ALIGN_START = 'start' as const;
const RULES = ['Administrador', 'Engenharia', 'Gerente', 'Cliente'];

const ROLE_PERMISSIONS = {
  Administrador: [
    'read-users',
    'edit-users',
    'remove-users',
    'create-users',
    'view-dashboard',
    'view-reports',
    'view-all-devices',
    'edit-devices',
    'remove-devices',
    'create-devices',
    'read-clients',
    'edit-clients',
    'remove-clients',
    'create-clients',
  ],
  Engenharia: [
    'read-users',
    'view-dashboard',
    'view-reports',
    'view-all-devices',
    'edit-devices',
    'remove-devices',
    'create-devices',
  ],
  Gerente: [
    'read-users',
    'edit-users',
    'remove-users',
    'create-users',
    'read-clients',
    'edit-clients',
    'remove-clients',
    'create-clients',
  ],
  Cliente: ['view-own-devices', 'view-dashboard', 'view-reports'],
};

const PERMISSIONS_MAPPING = {
  'read-users': { title: 'Ler usuários', icon: 'mdi-eye' },
  'edit-users': { title: 'Editar usuários', icon: 'mdi-pencil' },
  'remove-users': { title: 'Remover usuários', icon: 'mdi-delete' },
  'create-users': { title: 'Criar usuários', icon: 'mdi-plus' },
  'view-dashboard': { title: 'Visualizar Dashboard', icon: 'mdi-view-dashboard' },
  'view-reports': { title: 'Visualizar Relatórios', icon: 'mdi-file-document' },
  'view-all-devices': { title: 'Visualizar todos os dispositivos', icon: 'mdi-devices' },
  'view-own-devices': { title: 'Visualizar dispositivos próprios', icon: 'mdi-devices' },
  'edit-devices': { title: 'Editar dispositivos', icon: 'mdi-pencil' },
  'remove-devices': { title: 'Remover dispositivos', icon: 'mdi-delete' },
  'create-devices': { title: 'Criar dispositivos', icon: 'mdi-plus' },
  'read-clients': { title: 'Ler clientes', icon: 'mdi-eye' },
  'edit-clients': { title: 'Editar clientes', icon: 'mdi-pencil' },
  'remove-clients': { title: 'Remover clientes', icon: 'mdi-delete' },
  'create-clients': { title: 'Criar clientes', icon: 'mdi-plus' },
};

const EQUIPMENT_TYPES = {
  Cigarra: 1,
  'Cigarra Plus': 2,
  Vbox: 3,
  '7Trafo': 4,
  MotorTag: 5,
  Radio: 6,
  'Smart Grid Sensor': 7,
};

const EQUIPMENT_TECNOLOGIES = ['Lora', 'NBIoT', 'BLE', 'Wi-Fi', 'LTE'];

const TAB_INFO = 1;
const DEVICE_CONFIGURATION_TAB = 2;

const TAB_ASSOCIATED_DEVICES = 2;
const TAB_ASSOCIATED_USERS = 3;
const TAB_CONFIG_DEVICE = 4;

const HEADER_DEVICES_BY_CLIENT = [
  {
    align: ALIGN_CENTER,
    title: 'Nome',
    key: 'name',
    sortable: false,
    class: 'fw-semibold gray-color text-start',
  },
  {
    align: ALIGN_CENTER,
    title: 'UUID',
    key: 'uuid',
    sortable: false,
    class: 'fw-semibold gray-color',
  },
  {
    align: ALIGN_CENTER,
    title: 'Número de Operação',
    key: 'operational_number',
    sortable: false,
    class: 'fw-semibold gray-color',
  },
  {
    align: ALIGN_CENTER,
    title: 'Número de Linha',
    key: 'line_number',
    sortable: false,
    class: 'fw-semibold gray-color',
  },
  { align: ALIGN_CENTER, title: 'Ações', key: 'actions', sortable: false, class: 'fw-semibold gray-color' },
];

const HEADER_USERS_BY_CLIENT = [
  {
    align: ALIGN_START,
    title: 'Nome',
    key: 'name',
    sortable: false,
    class: 'fw-semibold gray-color text-start',
  },
  {
    align: ALIGN_START,
    title: 'Email',
    key: 'email',
    sortable: false,
    class: 'fw-semibold gray-color',
  },
  { align: ALIGN_START, title: 'Ações', key: 'actions', sortable: false, class: 'fw-semibold gray-color' },
];

const IDENTIFIER_KEY = {
  device: 'device_id',
  user: 'user_id',
};

const HEADER_REPORTS = {
  Cigarra: [
    {
      title: 'UUID do Dispositivo',
      key: 'device_uuid',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Tecnologia',
      key: 'technology',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status',
      key: 'status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Bateria',
      key: 'power',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Temperatura',
      key: 'temperature',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Data do Evento',
      key: 'date_event',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status do Acelerômetro',
      key: 'accelerometer_status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Força do Sinal',
      key: 'signal_strength_dbm',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status do Dispositivo',
      key: 'device_status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Nível da Bateria',
      key: 'battery_level',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Temperatura Ambiente',
      key: 'ambient_temperature_c',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Dados Transmitidos',
      key: 'data_transmitted',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Variação de Parâmetro',
      key: 'parameter_variance',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Porcentagem de Inclinação',
      key: 'inclination_percentage',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
  ],
  'Cigarra Plus': [
    {
      title: 'UUID do Dispositivo',
      key: 'device_uuid',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Tecnologia',
      key: 'technology',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status',
      key: 'status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Bateria',
      key: 'power',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Temperatura',
      key: 'temperature',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Data do Evento',
      key: 'date_event',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status do Acelerômetro',
      key: 'accelerometer_status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Força do Sinal',
      key: 'signal_strength_dbm',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status do Dispositivo',
      key: 'device_status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Nível da Bateria',
      key: 'battery_level',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Temperatura Ambiente',
      key: 'ambient_temperature_c',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Dados Transmitidos',
      key: 'data_transmitted',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Variação de Parâmetro',
      key: 'parameter_variance',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Porcentagem de Inclinação',
      key: 'inclination_percentage',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Corrente',
      key: 'current_amps',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Presença de Tensão',
      key: 'voltage_presence',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status do Link',
      key: 'link_status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
  ],
  'Smart Grid Sensor': [
    {
      title: 'UUID do Dispositivo',
      key: 'device_uuid',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Tecnologia',
      key: 'technology',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status',
      key: 'status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Bateria',
      key: 'power',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Temperatura',
      key: 'temperature',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Data do Evento',
      key: 'date_event',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status do Acelerômetro',
      key: 'accelerometer_status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Força do Sinal',
      key: 'signal_strength_dbm',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status do Dispositivo',
      key: 'device_status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Nível da Bateria',
      key: 'battery_level',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Temperatura Ambiente',
      key: 'ambient_temperature_c',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Dados Transmitidos',
      key: 'data_transmitted',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Variação de Parâmetro',
      key: 'parameter_variance',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Porcentagem de Inclinação',
      key: 'inclination_percentage',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Corrente',
      key: 'current_amps',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Presença de Tensão',
      key: 'voltage_presence',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status do Link',
      key: 'link_status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
  ],
  '7Trafo': [
    {
      title: 'UUID do Dispositivo',
      key: 'device_uuid',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Tecnologia',
      key: 'technology',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status',
      key: 'status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Bateria',
      key: 'power',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Temperatura',
      key: 'temperature',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Data do Evento',
      key: 'date_event',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Temperatura Ambiente',
      key: 'ambient_temp',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Temperatura do Óleo',
      key: 'oil_temp',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Vida Útil Restante',
      key: 'remaining_life',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Taxa de Envelhecimento',
      key: 'aging_rate',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Frequência (Hz)',
      key: 'frequency_hz',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status do Dispositivo',
      key: 'device_status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Última Mensagem',
      key: 'last_msg_epoch',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Nível de Sinal (dBm)',
      key: 'signal_level_dbm',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
  ],
};

const DEVICE_MONITORING_HEADERS = {
  Cigarra: [
    {
      title: 'UUID do Dispositivo',
      key: 'device_uuid',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    { title: 'Nome', key: 'name', sortable: true, class: 'fw-semibold gray-color text-start', align: 'ALIGN_CENTER' },
    {
      title: 'Status',
      key: 'status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Bateria',
      key: 'power',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Temperatura',
      key: 'temperature',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Data Mais Recente',
      key: 'latest_date',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Último Evento (segundos)',
      key: 'last_event_seconds',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Intervalo de Coleta (segundos)',
      key: 'collection_interval_seconds',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status do Acelerômetro',
      key: 'accelerometer_status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Força do Sinal (dBm)',
      key: 'signal_strength_dbm',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status do Dispositivo',
      key: 'device_status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Nível da Bateria',
      key: 'battery_level',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Temperatura Ambiente (°C)',
      key: 'ambient_temperature_c',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Porcentagem de Inclinação',
      key: 'inclination_percentage',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
  ],
  'Cigarra Plus': [
    {
      title: 'UUID do Dispositivo',
      key: 'device_uuid',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    { title: 'Nome', key: 'name', sortable: true, class: 'fw-semibold gray-color text-start', align: 'ALIGN_CENTER' },
    {
      title: 'Status',
      key: 'status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Bateria',
      key: 'power',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Temperatura',
      key: 'temperature',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Data Mais Recente',
      key: 'latest_date',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Último Evento (segundos)',
      key: 'last_event_seconds',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Intervalo de Coleta (segundos)',
      key: 'collection_interval_seconds',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status do Acelerômetro',
      key: 'accelerometer_status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Força do Sinal (dBm)',
      key: 'signal_strength_dbm',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status do Dispositivo',
      key: 'device_status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Nível da Bateria',
      key: 'battery_level',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Temperatura Ambiente (°C)',
      key: 'ambient_temperature_c',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Porcentagem de Inclinação',
      key: 'inclination_percentage',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Corrente (Amps)',
      key: 'current_amps',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Presença de Tensão',
      key: 'voltage_presence',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status do Link',
      key: 'link_status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
  ],
  'Smart Grid Sensor': [
    {
      title: 'UUID do Dispositivo',
      key: 'device_uuid',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    { title: 'Nome', key: 'name', sortable: true, class: 'fw-semibold gray-color text-start', align: 'ALIGN_CENTER' },
    {
      title: 'Status',
      key: 'status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Bateria',
      key: 'power',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Temperatura',
      key: 'temperature',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Data Mais Recente',
      key: 'latest_date',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Último Evento (segundos)',
      key: 'last_event_seconds',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Intervalo de Coleta (segundos)',
      key: 'collection_interval_seconds',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status do Acelerômetro',
      key: 'accelerometer_status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Força do Sinal (dBm)',
      key: 'signal_strength_dbm',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status do Dispositivo',
      key: 'device_status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Nível da Bateria',
      key: 'battery_level',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Temperatura Ambiente (°C)',
      key: 'ambient_temperature_c',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Porcentagem de Inclinação',
      key: 'inclination_percentage',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Corrente (Amps)',
      key: 'current_amps',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Presença de Tensão',
      key: 'voltage_presence',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status do Link',
      key: 'link_status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
  ],
  '7Trafo': [
    {
      title: 'UUID do Dispositivo',
      key: 'device_uuid',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    { title: 'Nome', key: 'name', sortable: true, class: 'fw-semibold gray-color text-start', align: 'ALIGN_CENTER' },
    {
      title: 'Status',
      key: 'status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Bateria',
      key: 'power',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Temperatura',
      key: 'temperature',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Data Mais Recente',
      key: 'latest_date',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Tensão A (RMS)',
      key: 'voltage_a_rms',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Tensão B (RMS)',
      key: 'voltage_b_rms',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Tensão C (RMS)',
      key: 'voltage_c_rms',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Corrente A (RMS)',
      key: 'current_a_rms',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Corrente B (RMS)',
      key: 'current_b_rms',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Corrente C (RMS)',
      key: 'current_c_rms',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Ângulo de Tensão A',
      key: 'voltage_angle_a',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Ângulo de Tensão B',
      key: 'voltage_angle_b',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Ângulo de Tensão C',
      key: 'voltage_angle_c',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Fator de Potência A',
      key: 'power_factor_a',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Fator de Potência B',
      key: 'power_factor_b',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Fator de Potência C',
      key: 'power_factor_c',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Potência A',
      key: 'power_a',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Potência B',
      key: 'power_b',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Potência C',
      key: 'power_c',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Energia A',
      key: 'energy_a',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Energia B',
      key: 'energy_b',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Energia C',
      key: 'energy_c',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Temperatura Ambiente',
      key: 'ambient_temp',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Temperatura do Óleo',
      key: 'oil_temp',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Vida Útil Restante',
      key: 'remaining_life',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Taxa de Envelhecimento',
      key: 'aging_rate',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Frequência (Hz)',
      key: 'frequency_hz',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Status do Dispositivo',
      key: 'device_status',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Última Mensagem',
      key: 'last_msg_epoch',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'Nível de Sinal (dBm)',
      key: 'signal_level_dbm',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'THD de Tensão A',
      key: 'voltage_thd_a',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'THD de Tensão B',
      key: 'voltage_thd_b',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'THD de Tensão C',
      key: 'voltage_thd_c',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'THD de Corrente A',
      key: 'current_thd_a',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'THD de Corrente B',
      key: 'current_thd_b',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'THD de Corrente C',
      key: 'current_thd_c',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'IHD de Tensão A',
      key: 'voltage_ihd_a',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'IHD de Tensão B',
      key: 'voltage_ihd_b',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'IHD de Tensão C',
      key: 'voltage_ihd_c',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'DTT 95',
      key: 'dtt_95',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'DTTP 95',
      key: 'dttp_95',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'DTTI 95',
      key: 'dtti_95',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
    {
      title: 'DTT3 95',
      key: 'dtt3_95',
      sortable: true,
      class: 'fw-semibold gray-color text-start',
      align: 'ALIGN_CENTER',
    },
  ],
};

const STATUS = [
  {
    name: 'Ativo',
    value: '1',
  },
  {
    name: 'Inativo',
    value: '0',
  },
];

const TYPES = {
  DEVICE: 'device',
  USER: 'user',
  CLIENT: 'client',
};

const NO_DATA_AVAILABLE = {
  DEVICE: 'Nenhum dispositivo encontrado',
  USER: 'Nenhum usuário encontrado',
};
const TABLE_PAGINATION_ITEMS = 5;

const ERROR_MESSAGE_COORDINATES =
  'As coordenadas inseridas são inválidas, verifique os valores de latitude e longitude e tente novamente.';

const SETTING_FIELDS = {
  VALUE: 'valor',
  DNP_TAG: 'Tag DNP',
  VARIABLE: 'variavel',
  DNP_ADDRESS: 'Endereço DNP',
};

export {
  RULES,
  ROLE_PERMISSIONS,
  PERMISSIONS_MAPPING,
  EQUIPMENT_TYPES,
  EQUIPMENT_TECNOLOGIES,
  HEADER_DEVICES_BY_CLIENT,
  HEADER_USERS_BY_CLIENT,
  TAB_INFO,
  TAB_ASSOCIATED_DEVICES,
  TAB_ASSOCIATED_USERS,
  IDENTIFIER_KEY,
  TYPES,
  NO_DATA_AVAILABLE,
  ERROR_MESSAGE_COORDINATES,
  TABLE_PAGINATION_ITEMS,
  HEADER_REPORTS,
  STATUS,
  DEVICE_MONITORING_HEADERS,
  DEVICE_CONFIGURATION_TAB,
  SETTING_FIELDS,
  TAB_CONFIG_DEVICE,
};
