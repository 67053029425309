import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LoginView from '../views/authentication/LoginView.vue';
import DashboardView from '../views/DashboardView.vue';
import ForgotPassword from '../views/authentication/ForgotPassword.vue';
import ResetPassword from '../views/authentication/ResetPasswordPage.vue';
import Error from '../views/error/ErrorView.vue';
import SuccessPasswordChange from '../views/SuccessPasswordChange.vue';
import ChangeFirstPasswordView from '../views/authentication/ChangeFirstPasswordView.vue';
import DevicesView from '../views/devices/DevicesListView.vue';
import UsersList from '../views/users/UsersList.vue';
import CigarraView from '../views/dashboard/CigarraView.vue';
import UserEdit from '../../src/views/users/UserEdit.vue';
import ClientsView from '../../src/views/clients/ClientsListView.vue';
import ClientEdit from '../../src/views/clients/ClientEditView.vue';
import store from '@/store';
import DeviceEdit from '@/views/devices/DevicesEditView.vue';
import ReportEventsView from '@/views/report/ReportEventsView.vue';
import QEETrafo from '@/views/report/QEETrafo.vue';
import SmartGridSensorView from '@/views/dashboard/SmartGridSensorView.vue';
import ComingSoonView from '@/views/coming-soon/ComingSoonView.vue';
import { getUserRole, getUser } from '@/utils/store';
import MonitoringDevice from '@/views/monitoring/MonitoringDevice.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot',
    component: ForgotPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/reset-password',
    name: 'reset',
    component: ResetPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/success-change',
    name: 'successChange',
    component: SuccessPasswordChange,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/',
    name: 'home',
    component: DashboardView,
    meta: {
      requiresAuth: true,
      roles: ['Administrador', 'Engenharia', 'Cliente'],
    },
  },
  {
    path: '/users',
    name: 'users',
    component: UsersList,
    meta: {
      requiresAuth: true,
      title: 'Usuários',
      icon: 'ri-user-line',
      roles: ['Administrador', 'Engenharia', 'Gerente'],
    },
  },
  {
    path: '/user/edit/:id',
    name: 'UserEdit',
    component: UserEdit,
    meta: {
      requiresAuth: true,
      roles: ['Administrador', 'Gerente'],
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
  {
    path: '/error/not-found',
    name: 'notFound',
    component: Error,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/coming-soon',
    name: 'ComingSoonView',
    component: ComingSoonView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/first-password',
    name: 'firstPassword',
    component: ChangeFirstPasswordView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/devices',
    name: 'DevicesView',
    component: DevicesView,
    meta: {
      requiresAuth: true,
      title: 'Dispositivos',
      icon: 'ri-device-line',
      roles: ['Administrador', 'Engenharia', 'Cliente'],
    },
  },
  {
    path: '/device/edit/:id',
    name: 'DeviceEdit',
    component: DeviceEdit,
    meta: {
      requiresAuth: true,
      roles: ['Administrador', 'Engenharia'],
    },
  },
  {
    path: '/clients',
    name: 'ClientsView',
    component: ClientsView,
    meta: {
      requiresAuth: true,
      title: 'Clientes',
      icon: 'ri-user-line',
      roles: ['Administrador', 'Gerente'],
    },
  },
  {
    path: '/clients/edit/:id',
    name: 'ClientEdit',
    component: ClientEdit,
    meta: {
      requiresAuth: true,
      roles: ['Administrador', 'Gerente'],
    },
  },
  {
    path: '/dashboard/cigarra',
    name: 'CigarraView',
    component: CigarraView,
    meta: {
      requiresAuth: true,
      roles: ['Administrador', 'Engenharia', 'Cliente'],
    },
  },
  {
    path: '/dashboard/smart-grid-sensor',
    name: 'SmartGridSensorView',
    component: SmartGridSensorView,
    meta: {
      requiresAuth: true,
      roles: ['Administrador', 'Engenharia', 'Cliente'],
    },
  },
  {
    path: '/reports/events',
    name: 'ReportEventsView',
    component: ReportEventsView,
    meta: {
      requiresAuth: true,
      roles: ['Administrador', 'Engenharia', 'Cliente'],
    },
  },
  {
    path: '/monitoring/devices',
    name: 'MonitoringDeviceView',
    component: MonitoringDevice,
    meta: {
      requiresAuth: true,
      roles: ['Administrador', 'Engenharia', 'Cliente'],
    },
  },
  {
    path: '/reports/qee-trafo',
    name: 'QEETrafo',
    component: QEETrafo,
    meta: {
      requiresAuth: true,
      roles: ['Administrador', 'Engenharia', 'Cliente'],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  const requiresAuth = to.meta.requiresAuth;
  const isLoginRoute = to.name === 'login';
  const isFirstAccess = store.getters.getFirstAccessStatus === 'true';
  const isFirstPasswordRoute = to.name === 'firstPassword';

  const userRole = getUserRole();
  const routeRoles = to.meta.roles as string[];

  if (from.name) {
    sessionStorage.setItem('previousPath', from.path);
  }

  if (!isAuthenticated && requiresAuth) {
    return next('/login');
  }

  if (isAuthenticated && isLoginRoute) {
    return next(from.path);
  }

  if (isAuthenticated && isFirstAccess && !isFirstPasswordRoute) {
    return next({ name: 'firstPassword' });
  }

  if (!getUser()) {
    return next('/error/not-found');
  }

  if (to.path === '/') {
    if (['Administrador', 'Engenharia', 'Cliente'].includes(userRole)) {
      return next({ name: 'SmartGridSensorView' });
    }

    if (userRole === 'Gerente') {
      return next({ name: 'ClientsView' });
    }
  }

  if (Array.isArray(routeRoles) && !routeRoles.includes(userRole)) {
    return next('/error/not-found');
  }

  next();
});

export default router;
